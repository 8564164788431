import QRadioCustom from 'src/components/RadioCustom/QRadioCustom.vue'
import EstadosBrasileiros from 'src/core/mixin/EstadosBrasileiros'
import NotificacaoMixin from 'src/core/mixin/NotificacaoMixin'
import vTopFilters from 'src/core/filters/vTopFilters'
import { LocalStorage, clone } from 'quasar'
import ConteudoModel from 'src/model/usuario/ConteudoModel'
import CredereLeadModel from 'src/model/usuario/CredereLeadModel'
import BitrixDealCarecaModel from 'src/model/usuario/BitrixDealCarecaModel'
// import BiitrixModel from 'src/model/usuario/BiitrixModel'

export default {
  name: 'FormAssinatura',
  components: {
    QRadioCustom
  },
  mixins: [NotificacaoMixin, EstadosBrasileiros, vTopFilters],
  props: {
    dados: {}
  },
  data () {
    return {
      loading: false,
      finalizado: false,
      enviadoSucesso: false,
      termosAceitos: false,
      optin: false,
      salvando: false,
      modalConteudo: false,
      tipoConteudo: '',
      conteudo: '',
      carregandoConteudo: false,
      modelBitrixDealCareca: new BitrixDealCarecaModel(),
      modelCredereLead: new CredereLeadModel(),
      carregamentos: [],
      // Hatch, Sedan, SUV, Crossover, Picape, Caminhonete, Furgão, Carro Esportivo, Carro Executivo
      listas: { carroceria_id: [
        {
          'id': 'Hatch',
          'nome': 'Hatch'
        },
        {
          'id': 'Sedan',
          'nome': 'Sedan'
        },
        {
          'id': 'SUV',
          'nome': 'SUV'
        },
        {
          'id': 'Crossover',
          'nome': 'Crossover'
        },
        {
          'id': 'Picape',
          'nome': 'Picape'
        },
        {
          'id': 'Caminhonete',
          'nome': 'Caminhonete'
        },
        {
          'id': 'Furgão',
          'nome': 'Furgão'
        },
        {
          'id': 'Carro Esportivo',
          'nome': 'Carro Esportivo'
        },
        {
          'id': 'Carro Executivo',
          'nome': 'Carro Executivo'
        }],
      // Manual, Automático, Automatizado, CVT
      cambio_id: [
        {
          'id': 'Manual',
          'nome': 'Manual'
        },
        {
          'id': 'Automático',
          'nome': 'Automático'
        },
        {
          'id': 'Automatizado',
          'nome': 'Automatizado'
        },
        {
          'id': 'CVT',
          'nome': 'CVT'
        }
      ] },

      thumbStyle: {
        right: '4px',
        borderRadius: '5px',
        backgroundColor: '#76528F',
        width: '5px',
        opacity: 0.75
      }

    }
  },
  watch: {},
  mounted: function () {
    // this.buscarTodasAsListagens()
    this.$store.commit('depois/putData', this.modelCredereLead.form)

    this.newModelCredereLead()
    this.modelCredereLead.form.birthdate = null
  },
  created () {
    this.models = {

    }
  },
  computed: {},
  methods: {
    submitCrederePasso1 () {
      if (this.possuiCarroEntrada) {
        if (this.modelCalcularPreco.form.placa_uf) {
          this.modelVeiculosEntrada.form.uf_placa = this.modelCalcularPreco.form.placa_uf
        }
        if (this.modelCalcularPreco.form.km) {
          this.modelVeiculosEntrada.form.km = this.modelCalcularPreco.form.km.toString()
        }

        const send = clone(this.modelVeiculosEntrada.form)

        if (this.dados && this.dados.valor_fipe) {
          send.valor_fipe = this.dados.valor_fipe
        }

        // if (!this.veiculoQuitado) {
        //   send.valor_saldo_devedor = this.veiculoSaldoDevedor
        // }
        // if (this.valorVeiculoComp <= this.modelVeiculosEntrada.form.valor_mercado) {
        //   this.notificacao('aviso', 'Valor do veículo de entrada é igual ou superior ao veículo anunciado!', 3000)
        // } else {
        this.salvandoPasso1 = true
        const aviso = this.notificacao('enviando')
        send.simulacao = true
        this.modelVeiculosEntrada
          .salvar(send)
          .then(res => {
            this.modelCredereProposta.form.veiculos_entradas_id =
              res && res.dados && res.dados.id ? res.dados.id : null

            aviso()
            this.notificacao('salvo', 'Salvo com sucesso', 3000)
            this.forcerRender++
            this.salvandoPasso1 = 'ok'
            this.currentStep = 1

            // this.$refs.formCrederePasso3.scrollIntoView({ behavior: 'smooth' })
          })
          .catch(error => {
            aviso()
            this.salvandoPasso1 = false
            this.notificacao('erro', error.msg)
          })
        // }
      } else {
        this.forcerRender++
        this.salvandoPasso1 = 'ok'
        this.currentStep = 1
        // this.$refs.formCrederePasso3.scrollIntoView({ behavior: 'smooth' })
      }
    },
    submitLead () {
      this.loading = true

      this.modelBitrixDealCareca.form.LEAD.NAME = this.modelCredereLead.form
        .name
        ? this.modelCredereLead.form.name
        : 'Sem Nome'
      this.modelBitrixDealCareca.form.LEAD.UF_CRM_1602009023 = this.$options.filters.soNumeros(
        this.modelCredereLead.form.cpf_cnpj
          ? this.modelCredereLead.form.cpf_cnpj
          : '1'
      )
      this.modelBitrixDealCareca.form.LEAD.EMAIL[0].VALUE = this
        .modelCredereLead.form.email
        ? this.modelCredereLead.form.email
        : `${this.modelBiitrix.form.LEAD.UF_CRM_1602009023}@netcaros.com.br`
      this.modelBitrixDealCareca.form.LEAD.PHONE[0].VALUE = this.$options.filters.soNumeros(
        this.modelCredereLead.form.phone_number
          ? this.modelCredereLead.form.phone_number
          : '1'
      )

      this.modelBitrixDealCareca.form.DEAL.UF_CRM_1651977119 =
        window.location.pathname

      let send = clone(this.modelBitrixDealCareca.form)

      var gclid = JSON.parse(localStorage.getItem('gclid'))
      if (gclid) {
        send.gclid = gclid.value
      }

      let nomeCompleto = send.LEAD.NAME
      let nomesArr = nomeCompleto.split(' ')

      if (nomesArr[0]) send.LEAD.NAME = nomesArr[0]
      if (nomesArr[1]) {
        send.LEAD.SECOND_NAME = nomesArr[1]
      } else {
        send.LEAD.SECOND_NAME = nomesArr[0]
      }
      if (nomesArr[2]) {
        send.LEAD.LAST_NAME = nomesArr[2]
      } else {
        send.LEAD.LAST_NAME = nomesArr[0]
      }

      send.DEAL.OPPORTUNITY = '0'
      send.DEAL.UF_CRM_1602179548 = false
      send.DEAL.UF_CRM_1606076503 = 833
      send.DEAL.UF_CRM_1611422640206 = 873
      send.DEAL.UF_CRM_1600859637 = 0
      send.DEAL.UF_CRM_1600913053 = 'SEM PLACA'
      send.DEAL.UF_CRM_603A9A8462094 = send.DEAL.UF_CRM_1600913053

      send.DEAL.UF_CRM_1600913067 = 'SEM MARCA'
      send.DEAL.UF_CRM_603A9A839CA41 = send.DEAL.UF_CRM_1600913067

      send.DEAL.UF_CRM_1600913084 = 'SEM MODELO'
      send.DEAL.UF_CRM_603A9A83E7E71 = send.DEAL.UF_CRM_1600913084
      send.DEAL.UF_CRM_1600913106 = 0
      send.DEAL.UF_CRM_603A9A843F6F0 = send.DEAL.UF_CRM_1600913106
      send.DEAL.UF_CRM_1600913147 = 0

      send.DEAL.UF_CRM_1600866673352 = this.modelCredereLead.form.cambio
      send.DEAL.UF_CRM_DEAL_1702321623463 = [this.modelCredereLead.form.carroceria]

      this.modelBitrixDealCareca
        .salvar(send)
        .then(res => {
          this.finalizado = true
          this.loading = false
          this.salvando = false
          this.enviadoSucesso = true
        })
        .catch(error => {
          this.loading = false
          this.salvando = false
          this.notificacao('erro', error.msg)
        })
    },

    newModelCredereLead () {
      this.modelCredereLead = new CredereLeadModel(this.usuarioSelecionado)
      if (this.$refs.formCrederePasso3) {
        this.$refs.formCrederePasso3.reset()
      }
    },
    // Methods Listagem
    buscarTodasAsListagens () {
      this.buscandoTodasAsListagens = true
      let promises = this.listagens.map(tipo => {
        if (tipo !== 'modelo_id' && tipo !== 'versao_id') {
          let model = new this.models[tipo]()
          return model.getListagem({ params: { limit: 1000 } })
        } else {
          return new Promise(resolve => {
            resolve(true)
          })
        }
      })
      Promise.all(promises)
        .then(response => {
          this.listagens.forEach((tipo, index) => {
            this.listas[tipo] = response[index].dados ?? []
          })
          // let listas = this.listas
          // if (LocalStorage.has('veiculo-listas')) {
          //   listas = LocalStorage.getItem('veiculo-listas')
          //   listas = [...listas, ...this.listas]
          // }
          LocalStorage.set('veiculo-listas', this.listas)
          this.buscandoTodasAsListagens = false
        })
        .catch(() => {
          this.buscandoTodasAsListagens = false
        })
    },
    removeDuplicates (myArr, prop, incluir = null) {
      return myArr.filter((obj, pos, arr) => {
        if (incluir !== null) {
          return (
            arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos ||
            incluir === obj['id']
          )
        } else {
          return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos
        }
      })
    },
    buscarListagemRequest (tipo, promise, search = '', relacao = false) {
      let model = new this.models[`${tipo}`](relacao)
      this.carregamentos[`${tipo}`] = true
      if (promise) {
        return model.getListagem({
          params: {
            search,
            limit: 1000,
            orderBy: relacao ? '' : 'nome',
            sortedBy: 'asc'
          }
        })
      } else {
        model
          .getListagem({
            params: { search, limit: 1000, orderBy: 'nome', sortedBy: 'asc' }
          })
          .then(response => {
            this.carregamentos[`${tipo}`] = false
            if (tipo === 'versao_id') {
              this.listaVersao = clone(response.dados)
              let data = []
              data[1] = this.removeDuplicates(
                response.dados,
                'nome',
                this.modelVeiculosEntrada.form.versao_id
              )
              response.dados = data[1]
            }
            if (
              this.modelVeiculosEntrada.form[`${tipo}`] > 0 &&
              response.dados.findIndex(
                ind => this.modelVeiculosEntrada.form[`${tipo}`] === ind.id
              ) === -1
            ) {
              this.modelVeiculosEntrada.form[`${tipo}`] = ''
            }
            this.listas[`${tipo}`] = response.dados ?? []

            if (LocalStorage.has('veiculo-listas')) {
              let listas = LocalStorage.getItem('veiculo-listas')
              listas[`${tipo}`] = response.dados
              LocalStorage.set(`veiculo-listas`, listas)
              this.keys[`${tipo}`]++
              if (tipo === 'versao_id') {
                this.validarAnoVeiculo()
              }
            }
            if (this.$refs.formCrederePasso1) {
              this.$refs.formCrederePasso1.resetValidation()
            }
          })
          .catch(() => {
            this.carregamentos[`${tipo}`] = false
          })
      }
    },

    validarCPF (cpf) {
      cpf = this.$options.filters.soNumeros(cpf)
      return cpf && cpf.length >= 11
        ? this.validarCPFCalculo(cpf.toString())
        : null
    },

    validarCPFCalculo (strCPF) {
      var Soma = 0
      var Resto = null
      if (strCPF === '00000000000') return false

      for (let i = 1; i <= 9; i++) {
        Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i)
      }
      Resto = (Soma * 10) % 11

      if (Resto === 10 || Resto === 11) Resto = 0
      if (Resto !== parseInt(strCPF.substring(9, 10))) return false

      Soma = 0
      for (let i = 1; i <= 10; i++) {
        Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i)
      }
      Resto = (Soma * 10) % 11

      if (Resto === 10 || Resto === 11) Resto = 0
      if (Resto !== parseInt(strCPF.substring(10, 11))) return false
      return true
    },

    validarEmail (email) {
      // eslint-disable-next-line no-useless-escape
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(email).toLowerCase())
    },
    abrirModal (tipo) {
      this.modalConteudo = true
      switch (tipo) {
        case 'termos':
          this.tipoConteudo = 'Termos de Uso'
          this.buscarConteudo('regulamento-termos-de-uso')
          break
        case 'privacidade':
          this.tipoConteudo = 'Política de Privacidade'
          this.buscarConteudo('regulamento-politica-privacidade')
          break
        default:
          this.buscarConteudo('regulamento-politica-privacidade')
          break
      }
    },
    buscarConteudo (chave) {
      const model = new ConteudoModel()
      this.carregandoConteudo = true
      model
        .getListagem({ params: { search: `chave_interna:${chave}` } })
        .then(response => {
          this.conteudo = response.dados[0].conteudo
          this.carregandoConteudo = false
        })
        .catch(error => {
          this.notificacao('erro', error.msg)
          this.modalConteudo = false
          this.carregandoConteudo = false
        })
    }
  }
}
